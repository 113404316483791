@import "colours";

.presenting-complaint-button {
  padding: 0 15px;

  &:nth-child(4n-3) {
    padding: 0 15px 0 0;
  }

  &:nth-child(4n) {
    padding: 0 0 0 15px;
  }
}

.outcome-button {
  padding: 0 15px;

  &:nth-child(3n-2) {
    padding: 0 15px 0 0;
  }

  &:nth-child(3n) {
    padding: 0 0 0 15px;
  }
}

@media only screen and (max-width: 575px) {
  .outcome-button,
  .presenting-complaint-button {
    padding: 0 !important;
  }
}

.actioned-question {
  border: 3px solid $mc-red-01;
}

.error-text{
  color: $mc-red-01 !important;
}

.readonly-question {
  padding: 10px 15px;
}
