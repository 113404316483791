@import "colours";
.stat-item {
  position: relative;
  height: 300px;
  min-width: 250px;
  padding: 15px 15px;
}

.bar-chart-wrapper {
  width: 135px!important;
}
.bar-wrapper {
  height: 180px;
  position:relative;
}
.bar-wrapper .bar {
  float: left;
  position: relative;
  width: 40px;
  height: 100%;
  border-bottom: 1px solid $mc-blue-01;
}
.bar-wrapper .bar .slideup {
  position: absolute;
  bottom: 0;
  width: 40px;
}
.bar-wrapper .bar .slideup span {
  display: block;
  position: absolute;
  margin: 5px;
  top: -30px;
}

.slideup {
  transition: height ease 1s !important;
  transition-delay: .5s !important;
}

.bar-chart-labels {
  //noinspection CssInvalidFunction
  font-size: clamp(0.85em, unquote("0.3vw + 0.45rem"), 0.85em) !important;
  width: 120px;
  padding-top: 0.5rem;
}

.horizontal-bar-base {
  width: 100%;
  height: 40px;
  //background-color: $mc-grey-01;
  z-index: 1;
}

.horizontal-bar-value {
  transition: all ease-in-out 1s;
  animation-delay: 0.5s;
  height: 100%;
  z-index: 200;
}

.horizontal-bar-axis {
  //border-left: 1px solid $mc-blue-01;
}

.horizontal-bar-chart-container {
  padding: 10px 0;
  margin-left: 1rem;
  margin-right: 1rem;
}

.horizontal-bar-axis-label {
  margin: 0;
  padding: 10px 0 0 5px;
}
