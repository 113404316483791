@import "colours";
@font-face {
  font-family:'Magistral';
  src: url("../fonts/Magistral-Bold.ttf");

}
@font-face {
  font-family:'Montserrat';
  src: url('../fonts/Montserrat-Regular.ttf');
}

$header-font: Montserrat;
$body-font: Montserrat;

li, a {
  text-decoration: none !important;
}

.body-font,
p, a {
  font-family: $body-font;
}

h1, h2, h3, h4, h5, h6,
.header-font {
  font-family: $header-font;
}

h5 {
  font-size: 1.1rem;
}

.point-eight-em {
  font-size: 0.8em !important;
}
.one-em {
  font-size: 1em !important;
}
.one-point-zero-five-em {
  font-size: 1.05em !important;
}
