@import "~bootstrap";
@import "~react-toastify/dist/ReactToastify.css";
@import "~store-fetch-wrappers/dist/styles/scss/index";
@import "~pulse_table/dist/styles/scss/index";
@import "~pulse-modal/dist/styles/scss/index";

@import "core/animations";
@import "core/audit";
@import "core/bar-charts";
@import "core/buttons";
@import "core/colours";
@import "core/dots";
@import "core/dropdown";
@import "core/filters";
@import "core/form";
@import "core/inputs";
@import "core/modal";
@import "core/icons";
@import "core/navigation";
@import "core/page";
@import "core/pagination";
@import "core/react-date-picker";
@import "core/stats-container";
@import "core/sidebar";
@import "core/table";
@import "core/toast";
@import "core/toggle";
@import "core/tooltip";
@import "core/typography";
@import "core/utility";
@import "core/variables";

@import "widgets/tabs";
@import "widgets/topbar";

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden !important;
}
