@import "colours";
@import "typography";
@import "variables";

.pagination {
  background-color: #fff;
}

.pagination-link {
 color: #8b98a4;
}

.pagination-link:hover {
  color: #3d5368;
  background-color: #fff;
}

.active-pagination-link {
  color: #3d5368;
  font-weight: 500;
}

.other-pages {
  color: #8b98a4;
}

.other-pages:hover {
  background-color: #fff;
}

.next-previous-icon {
  color: #8b98a4;
}

.next-previous-icon:hover {
  color: #3d5368 !important;
}

.active-page {
  background-color: #fff;
}

.active-page:hover {
  color: #fff;
}